import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Cloud Solitaire - Devops and Consulting for AWS and GCP</title>
                <meta name="description" content="Cloud Solitaire - Devops and Consulting for AWS and GCP" />
                <meta name="og:title" property="og:title" content="Cloud Solitaire - Devops and Consulting for AWS and GCP"></meta>
                <meta name="twitter:card" content="Cloud Solitaire - Devops and Consulting for AWS and GCP"></meta>
                {/* <link rel="canonical" href="https://rewy-react.envytheme.com/"></link> */}
                {/* <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" /> */}
            </Helmet>
        </div>
    )
}

export default SEO
